<template>
  <v-form
    ref="form"
    lazy-validation
    readonly
  >
    <!-- v-model="valid" -->

  <v-container>
    <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
      Mensaje recibido
    </div>

    <v-row v-if="loading"  justify="center">
        <v-progress-linear class="my-2"
        indeterminate
        rounded
        ></v-progress-linear>
    </v-row>
    <template v-else>
    <v-row style="height:60px" no-gutters>
      <v-col>
        <v-alert
          class="white--text h4"
          color="blue-grey darken-2"
          dense>
          {{asunto}}
        </v-alert>
      </v-col>
    </v-row>

    <v-row style="height:25px" no-gutters justify="start" class="mt-n2" >
      <v-col style="width:300px" cols="auto">
        <h6 align="left" style="height:20px; margin-left:10px">
          DE: {{MsjDe}}
        </h6>
      </v-col>
    </v-row>

    <v-row style="height:25px" no-gutters justify="start" class="mt-n2" >
      <v-col style="width:160px" cols="auto">
        <h6 align="left" style="height:20px; margin-left:10px">
          {{fecha}}
        </h6>
      </v-col>
    </v-row>

    <v-row style="height:25px" no-gutters justify="start" class="mt-n2" >
      <v-col style="width:300px" cols="auto">
        <h6 align="left" style="height:20px; margin-left:10px">
          PARA: {{MsjPara}}
        </h6>
      </v-col>
    </v-row>

    <v-row style="height:370px" no-gutters>
      <v-col @click="msgClicked">
        <div style="overflow:scroll; height: 370px" v-html="textoMensaje">
        </div>

        <!-- <editor
          api-key="dw69xsmlltsw13yowzz6fz9ltlfxsggcp3jw71uzxu3kimlj"
          tinymce-script-src="../../node_modules/@tinymce/tinymce-vue/lib/tinyymce-vue.min.js"
          v-model="textoMensaje"
          :init="{
            height: 370,
            menubar: false,
            toolbar: false,
            inline: false
          }"
          ref="textoMensajeCtrl"
          disabled="true"
        /> -->
      </v-col>
    </v-row>

    <v-row class="mt-4" no-gutters>
      <v-col cols="auto">
        <v-simple-table dense style="width: 500px">
          <template v-slot:default>
            <tbody>
              <tr
                v-for="item in Adjuntos"
                :key="item.FicNom"
              >
                <td class="font-weight-bold">{{ item.FicNom }}</td>
                <td><v-btn @click="downloadFile(item.FicNom, item.FicNomReal)" icon ><v-icon>mdi-download-circle-outline</v-icon></v-btn></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    </template>

    <v-row style="height:60px" no-gutters>
      <v-col>
      <div class="text-center">
          <v-btn
            color="normal" class="mt-4 mr-2" :disabled="loading || prevId == null || prevId <= 0"
            @click="goToMsj(prevId)" style="width:50px">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            color="normal"
            class="mt-4"
            @click="salir"
            style="width:100px"
            ref="salirBtn"
            >
            Salir
          </v-btn>
          <v-btn
            color="normal" class="mt-4 ml-2" :disabled="loading || nextId == null || nextId <= 0"
            @click="goToMsj(nextId)" style="width:50px">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
      </div>
      </v-col>
    </v-row>
  </v-container>
  </v-form>
</template>

<script>
  // import Editor from '@tinymce/tinymce-vue';
  import { mapState, mapMutations } from 'vuex';
  import router from '../router';
  import axios from 'axios';
  import storeRecibidos from '../store/modules/MensajesRecibidos.js'

  export default {
    components: {
      // 'editor': Editor
    },
    computed:{
        ...mapState(['empId', 'perId', 'perIdAux', 'esTienda', 'urlRaiz']),
        ...mapState('MensajesRecibidos', ['entidadActual', 'receptorPerId', 'perIdLector', 'msjId']),
        MsjPara: function() {
          if(this.Msj == null) { return ''; }

          let ParaStr = '';
          if (this.Msj.MsjPer.length >= 1) {
            ParaStr = this.Msj.MsjPer[0].PerNom;
          }
          else if (this.Msj.MsjPerCat.length >= 1) {
            ParaStr = this.Msj.MsjPerCat[0].PerCatNom;
          }

          if ((this.Msj.MsjPer.length + this.Msj.MsjPerCat.length) > 1) {
            ParaStr = ParaStr + ', . . . ';
          }
          return ParaStr;
        },
        MsjDe: function() {
          if(this.Msj == null || this.Msj.Per == null) { return ''; }
          return this.Msj.Per.PerNom;
        }
    },
    data: () => ({
      //perIdLector: 0,
      loading: false,
      fecha: '',
      asunto: '',
      textoMensaje: '',
      Adjuntos: [],
      Msj: null,
      nextId: null,
      prevId: null
    }),

  watch: {
  },
  beforeRouteEnter(to, from, next) {
    if (!from.name.startsWith('recibir-mensajes')) {
      storeRecibidos.state.filtros = {};
      next('/');
      return;
    }
    next()
  },
  methods: {
    ...mapMutations('MensajesRecibidos', ['msjIdSet']),
    getDataFromApi () {
        this.loading = true
        // return new Promise((resolve, reject) => {
        return new Promise((resolve) => {
          
          var controllerParameters = {
              Action: 'GET_MENSAJE_RECIBIDO',
              MsjId: this.msjId,
              PerId: this.receptorPerId,
              PerIdAux: this.perIdLector,
              Filtros: storeRecibidos.state.filtros
          };

          var AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/msj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
              const item = result.data.Msj;
              const next = result.data.Next;
              const prev = result.data.Previous;
            setTimeout(() => {
              this.loading = false
              resolve({
                item, next, prev
              })
            }, 1000)
          })
          .catch( error => {
            if (error.message.toUpperCase() == 'NETWORK ERROR')
            {
                // this.$router.push('/Login')            
            }
          })
        })
      },
    postInfVisToApi(fileName,  ambito, folderNames) {
      return new Promise((resolve) => {
        const controllerParameters = {
          Action: 'SAVE_INFVIS',
          EsTienda: this.esTienda,
          InfFic: fileName,
          PerId: this.perId,
          PerIdAux: this.perIdAux,
          InfAmbito: ambito,
          FolderNames: folderNames
        };       
        const AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.urlRaiz + "/api/inf", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
          const success = result.data;

          setTimeout(() => {
              resolve({
                success
              })
          }, 1000)
        });
      })
    },
    salir() {
      //  router.push('/recibir-mensajes');
      router.go(-1);  // puede venir de distinto orígenes
    },
    goToMsj(id) {
      if (id == null || id <= 0) return;
      this.msjIdSet(id);
      this.loadData();
    },
    loadData()
    {
      this.loading = true;
      this.getDataFromApi().then(data => {
          if(data.item == null) {
            alert('Mensaje no encontrado.');
            this.salir();
            return;
          }
          this.loading = false;

          this.Msj = data.item;
          this.nextId = data.next;
          this.prevId = data.prev;

          this.fecha = this.Msj.MsjFec; //this.entidadActual.MsjFecFormatted;
          this.asunto = this.Msj.MsjNom; //this.entidadActual.MsjNom;
          this.textoMensaje = this.Msj.MsjTxt; //this.entidadActual.MsjTxt;
          this.Adjuntos = this.Msj.MsjFic; //this.entidadActual.MsjFic;

          // let links = Array.from(document.getElementById('msgContainer').getElementsByTagName('a'));
          // console.log(links);

          // links.addEventListener('click', function(event) {
          //   console.log('Button Clicked');
          //   console.log(event)
          // });

          // if (this.entidadActual.MsjLeido != true) {
          if (this.Msj.MsjLeido != true) {

            // axios({ method: "POST", "url": this.urlRaiz + "/api/mensajes?Accion=leido&perid=" + this.receptorPerId, "data": entidadJson, "headers": { "content-type": "application/json" } })
            // .then(result => {
            // })

            var controllerParameters = {
              Action: 'SET_MENSAJE_LEIDO',
              PerId: this.perId,
              PerIdAux: this.perIdAux,
              EsEmpleado: this.$store.state.esEmpleado,
              // Msj: this.entidadActual,
              MsjId: this.Msj.MsjId,
              MsjPerId: this.Msj.PerId
            }

            // axios({ method: "POST", "url": this.urlRaiz + "/api/msj?Accion=leido&perid=" + this.receptorPerId, "data": entidadJson, "headers": { "content-type": "application/json" } })
            var AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": this.urlRaiz + "/api/msj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(() =>{
              this.$store.dispatch('GetMensajes');
            })
            .catch(error => {
              alert(error);
            })
          }
      });
    },
    msgClicked(e) {
      // const container = e.currentTarget;
      // const anchor = e.target;
      const path = e.path == null ? e.composedPath() : e.path;
      const anchor = path.find(tag => tag.tagName === 'A');

      // Si es un link
      if (anchor.href) {
        const docRegex = new RegExp('.+/RepositorioDocumentos/(Rincon|Martin|Flamenandco)/(Interna|Externa)/.+');
        // Si es el link de un documento
        if (docRegex.test(anchor.href)) {
          const hrefSplit = anchor.href.split('/');
          
          const indexAmbito = hrefSplit.findIndex(f => f.toUpperCase() == 'EXTERNA' || f.toUpperCase() == 'INTERNA')
          const ambito = hrefSplit[indexAmbito];
          
          const fileName = decodeURIComponent(hrefSplit[hrefSplit.length-1]);
          let folderNames = hrefSplit.slice(indexAmbito + 1, hrefSplit.length - 1);
          folderNames = folderNames.map(m => decodeURIComponent(m));
          
          this.postInfVisToApi(fileName, ambito, folderNames);
        }
      }
    },
    downloadFile (AdjuntoFicNom, AdjuntoFicNomReal) {
      var descarga = document.createElement('a');
      descarga.href = this.urlRaiz + '/Files/' + AdjuntoFicNomReal ;
      descarga.target = '_blank';
      descarga.download = AdjuntoFicNom;
      descarga.style.display = 'none';

      var clickEvent = new MouseEvent('click', { 'view':window, 'bubbles': true, 'cancelable': true });
      descarga.dispatchEvent(clickEvent);

      // var objFoundIndex = this.Adjuntos.findIndex(x=>x.FicNom == AdjuntoFicNom)

      // if (objFoundIndex >= 0) {
      //   this.Adjuntos.splice(objFoundIndex, 1)
      // }
    },
  },
  mounted() {
    this.loadData();
  }
}
</script>
